import React, { useContext, useState } from 'react';

import './Embed.scss';
import { Context } from '@components/Store';
import { Anchor } from '@components/Anchor';

import { getExternalSiteName, getPrivacyPolicy } from '@lib/util';

const Embed = props => {
    const [, dispatch] = useContext(Context);
    const [singleConsentGiven, setConsent] = useState();

    let consentGiven = {};

    if (typeof window !== 'undefined') {
      consentGiven = JSON.parse(localStorage.getItem('consentGiven')) || {};
    }

    const privacyPolicy = getPrivacyPolicy(props.src);
    const externalSiteName = getExternalSiteName(props.src);

    function setAspectRatio() {
      switch (props.aspectRatio) {

        case "1:1":
          return "ratio-square"

        case "16:9":
          return "ratio-16by9"

        default:
          return ""
      }
    }

    function setWidthFromRatio() {
        return props.aspectRatio.split(':')[0] || '';
    }

    function setHeightFromRatio() {
        return props.aspectRatio.split(':')[1] || '';
    }

    function handleClick(externalSiteName, persistSetting) {

        if (persistSetting) {
            dispatch({
                type: 'GIVE_CONSENT'
            });

            if (typeof window !== 'undefined') {
                localStorage.setItem('consentGiven', JSON.stringify({
                  ...consentGiven,
                  [externalSiteName.toLowerCase()]: true
                }));
            }
        } else {
            setConsent(true);
        }
    }

    return (
        <div className={`embed ${setAspectRatio(props.aspectRatio)}`.trim()}>
            {(consentGiven && consentGiven[externalSiteName.toLowerCase()]) || singleConsentGiven ? (
                    <iframe
                        allow={props.allow ? props.allow : 'autoplay'}
                        allowfullscreen={props.allowfullscreen ? props.allowfullscreen : null}
                        frameBorder="0"
                        loading="lazy"
                        scrolling="no"
                        src={props.src}
                        style={props.style}
                        title={props.title}
                        width={setWidthFromRatio()}
                        height={setHeightFromRatio()}
                    >
                    </iframe>
            ) : (
                <div className="cookie-consent">
                    <div>
                        <p>Do you want to load external content from {externalSiteName}? See their
                        {" "}
                        <Anchor
                            href={privacyPolicy}
                            title={`${externalSiteName} Privacy Policy`}
                        >privacy policy</Anchor>
                        {" "}
                        for details.</p>
                        <p><button type="button" onClick={e => handleClick(externalSiteName, true)}>Always Show</button></p>
                        <p><button type="button" className="link" onClick={e => handleClick(externalSiteName, false)}>Load content just this once</button></p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Embed;
